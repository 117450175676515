import React from "react";
import { Link } from "gatsby";

import { Layout } from "../components/index";

export default class NotFound extends React.Component {
  render() {
    return (
      <Layout {...this.props}>
        <div className="p-strip">
          <section className="page">
            <h2>Sorry, that page cannot be found.</h2>
            <p>Perhaps you were looking for one of these pages:</p>
            <ul>
              <li>
                <Link to="/pricing">Pricing</Link>
              </li>
              <li>
                <Link to="/blog">Blog</Link>
              </li>
              <li>
                <Link to="/book">Make a booking</Link>
              </li>
            </ul>
            <p>
              If not, please <Link to="/contact">get in touch</Link> and I'll
              happily respond ASAP.
            </p>
          </section>
        </div>
      </Layout>
    );
  }
}
